<template>
  <div class="evaluate">
    <div v-if="roleId == 5" class="evaluate-header">素质考评</div>

    <div v-else class="evaluate-header">
      <div>
        学段：
        <el-select
          v-model="searchItems.level"
          placeholder="全部学段"
          clearable
          @change="changeLevel"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        入学年份：
        <el-select
          v-model="searchItems.year"
          placeholder="入学年份"
          clearable
          @change="changeGrade"
        >
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        班级：
        <el-select
          v-model="searchItems.classNum"
          clearable
          placeholder="全部班级"
          @change="handleClick()"
        >
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        搜索：
        <el-input
          v-model="searchItems.keyword"
          placeholder="请输入内容"
          @keyup.enter.native="handleClick"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="handleClick"
          >
          </i>
        </el-input>
      </div>
      <el-button type="primary" @click="addEvaluate">新建考评</el-button>
    </div>
    <div v-loading="listLoading" style="min-heigth: 300px">
      <div v-if="evaluationList.length > 0" :key="newView">
        <evaluateItem
          v-for="(v, i) in evaluationList"
          :key="i"
          :evaluation="v"
          @getNewList="getNewList"
          @showList="showList"
        ></evaluateItem>
      </div>
      <div v-else style="background-color: #ffffff; padding: 20px">
        <no-data></no-data>
      </div>
    </div>

    <el-pagination
      v-if="total && roleId != 5"
      :current-page="current"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <el-dialog
      :title="evaluate.id ? '编辑考评' : '新建考评'"
      :visible.sync="dialogVisible"
      width="559px"
    >
      <div>
        <el-form
          ref="evaluateFrom"
          class="evaluate-from"
          :model="evaluate"
          :rules="evaluateRules"
          label-width="91px"
          size="mini"
        >
          <el-form-item label="考评名称：" prop="name">
            <el-input
              v-model="evaluate.name"
              placeholder="请输入考评名称"
              maxlength="15"
              show-word-limit
            ></el-input>
          </el-form-item>
          <div class="two-tows">
            <el-form-item label="考评时间：" prop="evaluationDate">
              <el-date-picker
                v-model="evaluate.evaluationDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                style="width: 156px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="选择学段：" prop="level">
              <el-select
                v-model="evaluate.level"
                placeholder="请选择选择学段"
                :disabled="evaluate.id ? true : false"
                @change="changeClassOptions(1)"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
                <!--  -->
              </el-select>
            </el-form-item>
          </div>
          <div class="two-tows">
            <el-form-item label="入学年份：" prop="year">
              <el-select
                v-model="evaluate.year"
                :disabled="evaluate.id ? true : false"
                placeholder="请选择入学年份"
                @change="changeClassOptions()"
              >
                <el-option
                  v-for="item in yearList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="考评年级：" prop="gradeId">
              <el-select
                v-model="evaluate.gradeId"
                placeholder="请选择考评年级"
              >
                <template v-if="evaluate.level == 2">
                  <el-option
                    v-for="item in gradeMiddleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
                <template v-if="evaluate.level == 3">
                  <el-option
                    v-for="item in gradeHighOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </el-form-item> -->
          </div>
          <el-form-item label="考评对象：" prop="audience">
            <el-radio-group v-model="evaluate.audience">
              <el-radio :label="1" :disabled="evaluate.id ? true : false">
                学生
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="考评班级：">
            <div>
              <el-button type="text" @click="examClassIdListTo(1)"
                >全选</el-button
              >
              <el-button type="text" @click="examClassIdListTo(2)"
                >反选</el-button
              >
              <el-button type="text" @click="examClassIdListTo(3)"
                >取消</el-button
              >
            </div>
            <div>
              <el-checkbox-group
                v-if="classOptionsModel.length > 0"
                v-model="evaluate.classList"
              >
                <el-checkbox
                  v-for="item in classOptionsModel"
                  :key="item.classNum"
                  :disabled="evaluate.id ? true : false"
                  :label="item.classNum + ''"
                >
                  {{ item.classNum }}
                </el-checkbox>
              </el-checkbox-group>
              <div v-if="classOptionsModel.length == 0">
                暂无可选班级，请确保学段和入学年份选择正确
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  levelOptions,
  gradeOptions,
  gradeHighOptions,
  gradeMiddleOptions,
} from "@/core/util/constdata";
import { getYear } from "@/core/util/util";
// import { queryExamClass } from "@/core/api/exam/exam";
import {
  addEvaluation,
  putEvaluation,
  evaluationPage,
  evaluationteacherList,
} from "@/core/api/evaluate/evaluate";
import {
  // getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
// D:\WTY_project\JZJX-ui-user-new\src\core\api\school\schoolgradeclass.js
// 48,15:     url: "/cms/schoolgradeclass/classlistbylevelandyear",
import { getStore } from "@/core/util/store";
import evaluateItem from "./component/evaluate-item";
export default {
  name: "Evaluate",
  components: {
    evaluateItem,
  },
  data() {
    return {
      current: 1,
      size: 10,
      total: 0,
      evaluationList: [],
      // 新增start
      btnLoading: false,
      dialogVisible: false,
      evaluateRules: {
        name: [{ required: true, message: "请输入考评名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        year: [
          { required: true, message: "请选择入学年份", trigger: "change" },
        ],
        audience: [
          { required: true, message: "请选择考评对象", trigger: "change" },
        ],
        classList: [
          { required: true, message: "请选择考评班级", trigger: "change" },
        ],
        gradeId: [
          { required: true, message: "请选择考评年级", trigger: "change" },
        ],
        evaluationDate: [
          {
            type: "string",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      evaluate: {
        name: "",
        evaluationDate: "",
        level: "",
        year: "",
        audience: "",
        // gradeId: "",
        classList: [],
      },
      // 新增end
      searchItems: {
        level: "",
        classNum: "",
        year: "",
        keyword: "",
      },

      yearList: getYear(),
      gradeOptions: gradeOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeHighOptions: gradeHighOptions,
      levelOptions: levelOptions(),
      classOptions: [],
      classOptionsModel: [],
      listLoading: false,
      roleId: "",
      newView: 1,
    };
  },
  created() {
    this.initView();
  },
  methods: {
    initView() {
      this.roleId = getStore({ name: "userInfo" }).roleId;
      if (this.roleId == 5) {
        this.evaluationteacherList();
      } else {
        this.evaluationPage();
      }
    },
    // 显示编辑框
    showList(item) {
      // console.log(item);
      for (let key in this.evaluate) {
        if (item[key]) {
          this.evaluate[key] = item[key];
        }
      }
      // console.log(this.evaluate);
      this.changeClassOptions();
      this.evaluate.id = item.id;

      // this.$nextTick(() => {
      this.evaluate.level = this.evaluate.level.toString();
      this.evaluate.classList = item.classNums.split(",");
      // });
      Object.assign(this.evaluate, {});
      this.dialogVisible = true;
    },
    // 获取新的列表
    getNewList() {
      this.handleClick();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.size = val;
      if (this.roleId == 5) {
        this.evaluationteacherList();
      } else {
        this.evaluationPage();
      }
    },
    handleCurrentChange(val) {
      this.current = val;
      if (this.roleId == 5) {
        this.evaluationteacherList();
      } else {
        this.evaluationPage();
      }
      // console.log(`当前页: ${val}`);
    },

    // 教师-获取素质测评列表
    evaluationteacherList() {
      let data = {
        current: this.current,
        size: this.size,
      };
      // Object.assign(data, this.searchItems);
      this.listLoading = true;
      evaluationteacherList(data)
        .then((res) => {
          if (res.data.data) {
            this.evaluationList = res.data.data;
          } else {
            this.evaluationList = [];
          }
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 获取列表
    evaluationPage() {
      let data = {
        current: this.current,
        size: this.size,
      };
      // Object.assign(data, );
      this.listLoading = true;
      evaluationPage(this.searchItems, data)
        .then((res) => {
          if (res.data.data) {
            this.evaluationList = res.data.data.records;
          } else {
            this.evaluationList = [];
          }
          this.newView = new Date().getTime();
          // this.evaluationList = res.data.data.records;
          this.total = res.data.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 添加素质考评
    addEvaluation() {
      let data = Object.assign({}, this.evaluate);
      // console.log(data);
      data.classNums = data.classList.join(",");
      delete data.classList;
      this.btnLoading = true;
      addEvaluation(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$message({
            message: "新增成功",
            type: "success",
            showClose: true,
          });
          this.handleClick();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 修改素质考评
    putEvaluation() {
      let data = Object.assign({}, this.evaluate);
      // console.log(data);
      data.classNums = data.classList.join(",");
      delete data.classList;
      this.btnLoading = true;
      putEvaluation(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$message({
            message: "修改成功",
            type: "success",
            showClose: true,
          });
          this.handleClick();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // /全选，反选，取消
    examClassIdListTo(val) {
      if (this.evaluate.id) {
        return;
      }
      if (val == 1) {
        this.evaluate.classList = [];
        this.classOptionsModel.forEach((i) => {
          this.evaluate.classList.push(i.classNum.toString());
        });
      }
      if (val == 2) {
        let arr = [];
        this.classOptionsModel.forEach((i) => {
          if (this.evaluate.classList.indexOf(i.classNum.toString()) == -1) {
            arr.push(i.classNum.toString());
          }
        });
        this.evaluate.classList = arr;
      }
      if (val == 3) {
        this.evaluate.classList = [];
      }
    },
    // 修改入学年份，根据学段和入学年份查询班级
    changeClassOptions(val) {
      if (val) {
        this.evaluate.gradeId = "";
      }
      this.evaluate.classList = [];
      // console.log(this.evaluate.level, this.evaluate.year);
      if (this.evaluate.level && this.evaluate.year) {
        let query = { level: this.evaluate.level, year: this.evaluate.year };
        getClassListByLevelAndYear(query).then((response) => {
          this.classOptionsModel = response.data.data;
        });
      }
    },
    submit() {
      this.$refs.evaluateFrom.validate((valid) => {
        if (valid) {
          // alert("submit!");
          if (this.evaluate.id) {
            this.putEvaluation();
          } else {
            this.addEvaluation();
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    addEvaluate() {
      // Object.assign(this.evaluate, this.$options.data().evaluate);
      this.evaluate = this.$options.data().evaluate;
      this.classOptionsModel = [];
      if (this.$refs.evaluateFrom) {
        this.$refs.evaluateFrom.resetFields();
      }

      this.dialogVisible = true;
    },
    handleClick() {
      this.current = 1;
      this.evaluationPage();
    },
    // 刷新班级列表
    refreshClassList() {
      // 班级搜索字符串 格式： level_year,level_year
      if (!this.searchItems.level || !this.searchItems.year) {
        return;
      }
      let query = {
        level: this.searchItems.level,
        year: this.searchItems.year,
      };
      this.classOptions = [];
      getClassListByLevelAndYear(query).then((response) => {
        response.data.data.map((item) => {
          let data = {
            value: item.classNum,
            label: item.classNum,
          };
          this.classOptions.push(data);
        });
        // console.log(this.classOptions);
      });
    },
    changeLevel() {
      this.handleClick();
    },
    changeGrade() {
      this.handleClick();
      this.refreshClassList();
    },
  },
};
</script>
<style scoped lang="scss">
.evaluate {
  padding-bottom: 40px;
  .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .evaluate-from {
    ::v-deep .el-form-item__label {
      padding: 0;
      margin-bottom: 0;
      line-height: 32px;
    }
    .el-radio {
      margin-bottom: 0;
      line-height: 32px;
    }
    .el-checkbox {
      min-width: 84px;
      margin-right: 0;
    }
    ::v-deep .el-input--mini .el-input__inner {
      line-height: 32px;
      height: 32px;
    }
    .el-form-item {
      .el-button {
        line-height: 32px;
      }
    }
    .two-tows {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-select {
        width: 156px;
      }
      .el-form-item {
        &:first-child {
          margin-right: 17px;
        }
      }
    }
  }

  .evaluate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    background-color: #ffffff;
    padding: 18px 24px;
    .el-select {
      width: 140px;
      margin-right: 18px;
    }
    .el-input {
      width: 140px;
      i {
        cursor: pointer;
      }
    }
  }
}
</style>
