<template>
  <div class="evaluate-item">
    <div class="evaluate-item-left">
      <div class="massage-box">
        <!-- 0-未开始 1-进行中 2-已结束 3-已发布 -->
        <template v-if="roleId != 5">
          <span v-if="evaluation.status == 0" class="tips">未开始</span>
          <span v-if="evaluation.status == 1" class="tips tips-suss"
            >进行中</span
          >
          <span v-if="evaluation.status == 2" class="tips tips-end"
            >已结束</span
          >
          <span v-if="evaluation.status == 3" class="tips tips-up">已发布</span>
        </template>
        <span class="grade" :class="{ 'grade-left': roleId == 5 }"
          >{{ evaluation.level == 3 ? "高" : "初"
          }}{{ evaluation.year }}级</span
        >
        <span class="title">{{ evaluation.name }}</span>
        <span>参与学生：{{ evaluation.total || 0 }}人</span>
        <span>考评日期：{{ evaluation.evaluationDate }}</span>
      </div>
      <div v-if="roleId != 5" class="btn-list">
        <el-button type="text" @click="dialogVisible = true">
          <img src="@/static/evaluate/message.png" alt="" />
          <img class="on" src="@/static/evaluate/message_on.png" alt="" />
          基本信息
        </el-button>
        <el-button type="text" @click="toLink('EvaluateStudent')">
          <img src="@/static/evaluate/student.png" alt="" />
          <img class="on" src="@/static/evaluate/student_on.png" alt="" />
          学生管理
        </el-button>
        <el-button type="text" @click="toLink('Evaluation')">
          <img src="@/static/evaluate/evaluation.png" alt="" />
          <img class="on" src="@/static/evaluate/evaluation_on.png" alt="" />
          考评表管理
        </el-button>
        <template v-if="evaluation.status == 0 || evaluation.status == 1">
          <el-button type="text" @click="toLink('EvaluateDistribute')">
            <img src="@/static/evaluate/allocation.png" alt="" />
            <img class="on" src="@/static/evaluate/allocation_on.png" alt="" />
            考评分配
          </el-button>
          <el-button type="text" @click="toLink('EvaluateProgress')">
            <img src="@/static/evaluate/tail_after.png" alt="" />
            <img class="on" src="@/static/evaluate/tail_after_on.png" alt="" />
            进度跟踪
          </el-button>
        </template>
        <template v-else>
          <el-button type="text" @click="toLink('EvaluateAnalyse')">
            <img src="@/static/evaluate/analyse.png" alt="" />
            <img class="on" src="@/static/evaluate/analyse_on.png" alt="" />
            统计分析
          </el-button>
          <el-button type="text" @click="publish()">
            <img src="@/static/evaluate/news.png" alt="" />
            <img class="on" src="@/static/evaluate/news_on.png" alt="" />
            成绩发布
          </el-button>
        </template>
      </div>
      <template v-else>
        <div class="progress teacher-progress">
          <span
            >我的任务：{{
              evaluation.studentsMark + " / " + evaluation.students
            }}人</span
          >
          <span>考评进度：</span>
          <el-progress :percentage="evaluation.progress || 0"></el-progress>
        </div>
      </template>
    </div>
    <div class="evaluate-item-right">
      <div v-if="roleId != 5" class="progress">
        <span>考评进度：</span>
        <el-progress :percentage="evaluation.progress || 0"></el-progress>
      </div>
      <div v-if="roleId != 5" class="edit-btn-box">
        <div>
          <el-button v-if="evaluation.status == 0" type="text" @click="edit()">
            <img src="@/static/evaluate/edit.png" alt="" />
            <img class="on" src="@/static/evaluate/edit_on.png" alt="" />
            编辑
          </el-button>
          <el-button type="text" @click="del()">
            <img src="@/static/evaluate/delete.png" alt="" />
            <img class="on" src="@/static/evaluate/delete_on.png" alt="" />
            删除
          </el-button>
        </div>
        <el-button
          v-if="evaluation.status == 0 || evaluation.status == 2"
          type="primary"
          class="status-btn"
          @click="switchIndex(0)"
        >
          <img src="@/static/evaluate/start.png" alt="" />
          开启考评
        </el-button>
        <el-button
          v-if="evaluation.status == 1"
          type="warning"
          class="status-btn"
          @click="switchIndex(1)"
        >
          <img src="@/static/evaluate/end.png" alt="" />
          结束考评
        </el-button>
        <el-button
          v-if="evaluation.status == 3"
          type="warning"
          class="status-btn"
          :loading="btnLoading"
          @click="revocation()"
        >
          <img src="@/static/evaluate/start.png" alt="" />
          撤销发布
        </el-button>
      </div>
      <template v-else>
        <el-button
          v-if="evaluation.status == 1"
          type="success"
          class="status-btn"
          @click="start(0)"
        >
          开始考评
        </el-button>
        <el-button
          v-if="evaluation.status == 2 || evaluation.status == 3"
          type="primary"
          class="status-btn"
          @click="toLink('EvaluateAnalyse')"
        >
          统计分析
        </el-button>
      </template>
    </div>

    <el-dialog title="基本信息" :visible.sync="dialogVisible" width="559px">
      <div class="evaluate-msg">
        <div><span>考评名称：</span>{{ evaluation.name }}</div>
        <div><span>考评日期：</span>{{ evaluation.evaluationDate }}</div>
        <div>
          <span>学段：</span>{{ evaluation.level | setName(levelOptions) }}
        </div>
        <div>
          <span>入学年份：</span>{{ evaluation.level == 3 ? "高" : "初"
          }}{{ evaluation.year }}级
        </div>
        <div>
          <span>考评对象：</span
          >{{ evaluation.audience == 1 ? "学生" : "教师" }}
        </div>
        <div><span>班级：</span>{{ evaluation.classNums }}</div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <el-dialog
      title="成绩发布"
      :visible.sync="publishDialogVisible"
      width="580px"
    >
      <div class="publish-box">
        <div class="tips">
          <span>!</span>注意：成绩发布后无法再开启考评重新评分！
        </div>
        <p>请选择需要发布的对象</p>
        <div>
          <!-- teacherRole -->
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(v, i) in teacherRole"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-checkbox
            >
            <!-- <el-checkbox :label="6">宿管</el-checkbox> -->
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="publishDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteEvaluation,
  evaluationRevoke,
  evaluationSwitch,
  evaluationPublish,
  statisticsevaluation,
} from "@/core/api/evaluate/evaluate";
import { levelOptions, teacherRole } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
export default {
  name: "EvaluateItem",
  components: {},
  props: {
    evaluation: {
      type: Object,
      default() {
        // return val;
      },
    },
  },
  data() {
    return {
      levelOptions: levelOptions(),
      teacherRole: teacherRole,
      checkList: [],
      btnLoading: false,
      dialogVisible: false,
      publishDialogVisible: false,
    };
  },
  created() {
    // this.evaluation = Object.assign({}, this.evaluationObj);
    this.roleId = getStore({ name: "userInfo" }).roleId;
  },
  methods: {
    submit() {
      // console.log(this.checkList);
      if (this.checkList.length == 0) {
        this.$message({
          message: "请选择需要发布的对象！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let data = {
        id: this.evaluation.id,
        roles: this.checkList.join(","),
      };
      this.evaluationPublish(data);
    },
    publish() {
      this.publishDialogVisible = true;
      // publish_role
      let publishRole = this.evaluation.publishRole;
      if (publishRole) {
        this.checkList = publishRole.split(",").map(Number);
      } else {
        this.checkList = [1, 2, 3, 4, 5, 6];
      }
    },
    evaluationSwitch(data) {
      evaluationSwitch(data).then(() => {
        this.$emit("getNewList");
        if (data.evaluationSwitch == 1) {
          this.$message({
            message: "关闭成功！",
            type: "success",
            showClose: true,
          });
          this.statisticsevaluation();
        } else {
          // this.evaluation.status = 1;
          this.$message({
            message: "开启成功！",
            type: "success",
            showClose: true,
          });
        }
      });
    },
    // 发布成绩
    evaluationPublish(data) {
      this.btnLoading = true;
      evaluationPublish(data)
        .then(() => {
          this.$emit("getNewList");
          this.$message({
            message: "发布成功！",
            type: "success",
            showClose: true,
          });
          this.btnLoading = false;
          this.publishDialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 开始统计
    statisticsevaluation() {
      let data = {
        id: this.evaluation.id,
      };
      statisticsevaluation(data).then(() => {});
    },
    // 开启  关闭
    switchIndex(val) {
      // console.log(this.evaluation);
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "正在"),
          h("span", { style: "color: red" }, val ? " 结束 " : " 开启 "),
          h("span", null, "考评 "),
          h("span", { style: "color: red" }, this.evaluation.name),
          h("span", null, " 是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let data = {
            evaluationSwitch: val,
            id: this.evaluation.id,
          };
          this.evaluationSwitch(data);
        })
        .catch(() => {});
    },
    del() {
      // console.log(this.evaluation);
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "正在删除考评 "),
          h("span", { style: "color: red" }, this.evaluation.name),
          h("span", null, " 删除后不可恢复，是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.deleteEvaluation();
        })
        .catch(() => {});
    },
    revocation() {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "正在撤销考评 "),
          h("span", { style: "color: red" }, this.evaluation.name),
          h("span", null, " 撤销后用户无法查看到已发布数据，是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.evaluationRevoke();
        })
        .catch(() => {});
    },
    edit() {
      this.$emit("showList", this.evaluation);
    },
    evaluationRevoke() {
      let data = {
        id: this.evaluation.id,
      };
      this.btnLoading = true;
      evaluationRevoke(data)
        .then(() => {
          this.$message({
            message: "撤回成功！",
            type: "success",
            showClose: true,
          });
          this.btnLoading = false;
          this.$emit("getNewList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    deleteEvaluation() {
      deleteEvaluation(this.evaluation.id).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
          showClose: true,
        });
        this.$emit("getNewList");
      });
    },
    toLink(name) {
      this.$router.push({
        name: name,
        query: { id: this.evaluation.id },
      });
    },
    start() {
      this.$router.push({
        name: "EvaluateAnswerSheet",
        query: { id: this.evaluation.id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.evaluate-item {
  margin-top: 24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  .publish-box {
    > p {
      margin: 18px 0;
    }
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      .el-checkbox {
        margin-right: 0;
      }
    }
    .tips {
      padding: 10px 25px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      span {
        line-height: 14px;
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 8px;
        font-size: 12px;
        letter-spacing: 0;
        text-align: center;
        color: #ffffff;
        border-radius: 50%;
        background-color: #f17e5e;
      }
    }
  }

  .status-btn {
    ::v-deep span {
      display: flex;
      img {
        margin-right: 3px;
      }
    }
  }
  .evaluate-msg {
    div {
      margin-bottom: 18px;
      color: #101011;
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      display: inline-block;
      text-align: right;
      margin-right: 18px;
      width: 80px;
      color: #5f5f68;
      line-height: 21px;
    }
  }
  .evaluate-item-left {
    flex-shrink: 0;
    .el-button {
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    }
  }
  .evaluate-item-right {
    display: flex;
  }
  .massage-box {
    span {
      color: #101011;
    }
    .title {
      font-weight: 500;
    }
    span + span {
      margin-left: 18px;
    }
    .grade {
      border: 1px solid #04c391;
      color: #04c391;
      padding: 4px;
      border-radius: 2px;
      margin-left: 8px;
    }
    .grade-left {
      margin-left: 0;
    }
    .tips {
      padding: 4px;
      border-radius: 2px;
      border: 1px solid #d7d7d7;
      background-color: #d7d7d7;
      color: #ffffff;
    }
    .tips-suss {
      background-color: #04c391;
      border: 1px solid #04c391;
    }
    .tips-end {
      background-color: #ea852c;
      border: 1px solid #ea852c;
    }
    .tips-up {
      background-color: #2474ed;
      border: 1px solid #2474ed;
    }
  }
  .btn-list {
    margin-top: 28px;
    .el-button + .el-button {
      margin-left: 32px;
    }
  }
  .el-button--text {
    color: #5f5f68;
    ::v-deep span {
      display: flex;
    }
    img {
      display: block;
      margin-right: 5px;
    }
    .on {
      display: none;
    }
    &:hover {
      img {
        display: none;
      }
      .on {
        display: block;
      }
      color: $primary-color;
    }
  }
  .edit-btn-box {
    text-align: right;
    flex-shrink: 0;
    > .el-button {
      margin-top: 18px;
    }
  }
  .teacher-progress.progress {
    // min-width: 50%;
    width: 100%;
    padding: 24px 0 0 0;
    background-color: #ffffff;
    justify-content: flex-start;
    .el-progress {
      width: 200px;
      justify-content: flex-start;
    }

    > span {
      &:first-child {
        display: inline-block;
        // margin-right: 24px;
        min-width: 200px;
      }
    }
  }

  .progress {
    width: 30vw;
    margin-right: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 31px 24px;
    background: #fcfcfc;
    ::v-deep .el-progress__text {
      // width: 90%;
      flex-shrink: 0;
      // margin-top: 6px;
    }
    ::v-deep .el-progress-bar__outer {
      border-radius: 0;
    }
    ::v-deep .el-progress-bar__inner {
      border-radius: 0;
    }
    ::v-deep .el-progress-bar {
      padding-right: 50px !important;
    }
    ::v-deep .el-progress {
      display: flex;
      justify-content: space-between;
    }
    .el-progress {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    > span {
      flex-shrink: 0;
    }
  }
  @media screen and (max-width: 1600px) {
    .progress {
      width: 24vw;
      margin-right: 2vw;
    }
  }
}
</style>
